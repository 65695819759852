/* .modal-content {
    min-width: 609px;
    border: none;
    border-radius: unset;
}

.modal-body {
    display        : flex;
    justify-content: center;
    align-items    : center;
    min-height     : 195px;
} */
.contained-modal-change-password .modal-content {
  padding: 30px 26px !important;
}
