.admin-add-links-sec {
  width: 600px;
  /* margin: auto; */
  /* margin-top: 30px; */
  /* margin-left: 48px; */
}
.add-link-item {
  margin-top: 20px;
}
.add-link-item p {
  margin-bottom: 5px;
}
.add-link-item input.input-box {
  width: 100%;
  height: 45px;
  border: 1px solid #d9d9d9;
  padding-left: 10px;
  border-radius: 4px;
}
.daily-quote-header-text h2 {
  /* font-family: Poppins; */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px; /* 135% */
}
.submit-btn-links {
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: right;
}
.submit-btn-links button {
  background-color: #ff5733;
  border-radius: 11px;
  color: #fff;
  /* font-family: Oswald; */
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  height: 50px;
  letter-spacing: 1.25px;
  line-height: 28px;
  width: 192px;
  text-align: center;
  text-transform: uppercase;
  border: none;
}
