.loader_wrapper {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: rgb(0 0 0 / 42%);
  z-index: 999999999;
}

.css_loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.css_loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 45px;
  height: 45px;
  margin: 5px;
  border: 5px solid #fff;
  border-radius: 50%;
  animation: css_loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.css_loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.css_loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.css_loader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes css_loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* .select__menu,
.select__menu * {
  color: red;
  z-index: inherit;
  z-index: 9999999999999;
  background-color: #000;
} */
/* css loader ends */
