#navbarCollapse {
  display: flex !important;
  align-items: center;
  width: 100%;
  height: 70px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  /* background-color: #e2e2e2; */
  /* background-color: #e2e2e2;  1db954*/
}
#headerWrapper {
  height: 70px;
  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 99999;
}
.mainLogoImage {
  max-width: 75px;
}
#navbarCollapse ul {
  flex-direction: row;
  /* margin-right: auto !important;
    margin-left: auto !important;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; */
}
.leftsideItem img {
  cursor: pointer;
  height: 17px;
}
.signInButtonArea {
  margin-left: 25px;
}
.signInButton {
  background-color: #ff5733;
  color: #fff;
  border: none;
  padding: 5px 20px;
  border-radius: 10px;
  background: #ff5733;
  /* color: var(--Shade-0, #fff); */
  /* Button Text/Medium */
  /* font-family: Poppins; */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.028px;
  text-transform: uppercase;
}
.signInButton1 {
  /* Button Text/Medium */
  /* font-family: Poppins; */
  font-size: 14px;
  font-style: normal;
  color: #000;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.028px;
  text-transform: uppercase;
  margin-right: 34px;
  border-radius: 0px;
  background: none;
  background-color: #fff;
  border: none;
}
#navbarCollapse ul li {
  display: inline;
  padding: 0px 20px;
}
.centersideItem input {
  width: 300px;
  height: 38px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding-left: 10px;
}
.cart_items_count {
  position: absolute;
  margin-top: -5px;
  color: #222020;
}
.rightsideItem > span {
  cursor: pointer;
}
#navbarCollapse ul li a {
  text-decoration: auto;
  color: #000;
}
.headerSection {
  background-color: #ffffff;
}
.allItemsSection {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
}
.allItemsSection > div {
  text-align: center;
  /* height: 70px; */
}
.cartIconPage {
  margin-top: 4px;
}

.leftsideItem,
.rightsideItem {
  width: 25%;
}
.allItemsSection .leftsideItem {
  text-align: left;
}
.allItemsSection .rightsideItem {
  display: flex;
  padding-top: 5px;
  text-align: right;
  justify-content: right;
}
.centersideItem {
  width: 50%;
}

.profileIconStyles {
  width: 38px;
  height: 38px;
  background-color: #ccc;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.menuDropdownMainSection {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  width: 200px;
  background-color: #fff;
  padding: 10px 20px;
}
.menuDropdownMainSection .items-list {
  cursor: pointer;
  margin: 10px 0px;
  padding: 5px 0px;
}
.Popover-body .menuDropdownMainSection > div:not(.items-list-signout) {
  border-bottom: 1px solid #e2e2e2;
}
@media (max-width: 600px) {
  .centersideItem input {
    width: 85%;
  }
}
