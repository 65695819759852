.bodySection {
  margin: auto;
  color: #333;
  /* background: #f5f5f5; */
}
body {
  font-family: inherit;
  font-size: 16px;
  color: #333;

  background-color: #f9f9f9 !important;
}
iframe {
  display: none;
}
.adminControlApp {
  min-height: 1000px;
  /* display: inline-block; */
  background: #f5f5f5;
}
.my-account-sec-bottom {
  width: 100%;
  display: flex;
  /* margin-top: 70px; */
  gap: 45px;
}
.my-account-sec-top {
  margin-bottom: 30px;
  margin-top: 8px;
  border-bottom: 1px solid #cfd3d7;
}
.my-account-sec-top h2 {
  color: #000;
  /* Heading 06/H6/Semibold */
  /* font-family: Poppins; */
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px; /* 132% */
}
.my-account-sec-top p {
  color: #000;
  /* Paragraph 03/Regular */
  /* font-family: Poppins; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 185.714% */
}
.layout-container {
  padding-top: 90px;
  width: 90%;
  min-height: 100vh;
  margin: auto;
  margin-bottom: 50px;
}
.full-content-sec-page-design {
  width: 95%;
  display: flex;
  /* margin-top: 70px; */
  gap: 20px;
  /* margin-top: 20px; */
}
.left-menu-side-content {
  width: 25%;
  /* background: #e2e2e2;
  padding-left: 20px; */
  border-radius: 4px;
  /* border: 1px solid #c4c4c4; */
}
.right-menu-side-content {
  width: 100%;
  border-radius: 4px;
  /* border: 1px solid #c4c4c4; */
  min-height: 100vh;
}
.right-menu-side-content1 {
  width: 100%;
  border-radius: 4px;
  /* border: 1px solid #c4c4c4; */
  min-height: 100vh;
}
.image-upload-model-box .modal-dialog {
  max-width: 500px;
}
.image-upload-model-box .modal-content {
  padding: 24px !important;
}
