/* .modal-content {
    min-width: 609px;
    border: none;
    border-radius: unset;
}

.modal-body {
    display        : flex;
    justify-content: center;
    align-items    : center;
    min-height     : 195px;
} */
.modal-content {
  padding: 50px 16px 30px 16px !important;
}
.modalBodyText {
  /* font-family: Noto Serif; */
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 40px;
  color: #000000;
  margin: 0 !important;
  text-align: center;
}

.modalCandelBtn {
  width: 154px;
  height: 50px;
  border: 1px solid #000000;
  background: #ffffff;
  border-radius: 4px;
  /* font-family: Poppins; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-right: 16px;
  border-radius: 12px !important;
  border: none !important;
  background: #ff5733 !important;
}

.modalCandelBtn:hover,
.modalCandelBtn:focus,
.modalCandelBtn:active {
  background-color: #ffffff !important;
  border: 1px solid #000000 !important;
  color: #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.modalDeleteBtn {
  width: 148px;
  height: 50px;
  background: #000000 !important;
  border: none;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  border-radius: 12px !important;
  border: none !important;
  background: #ff5733 !important;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.028px;
  text-transform: uppercase;
}

.modalDeleteBtn:hover,
.modalDeleteBtn:focus,
.modalDeleteBtn:active {
  box-shadow: none !important;
  background-color: #ff5733 !important;
}
.modal-footer {
  border: none !important;
}
