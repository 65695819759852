@media all and (min-width: 480px) {
  .LoginContainer {
    padding: 10px 0;
  }

  .LoginContainer form {
    margin: 0 auto;
    max-width: 320px;
  }
}
.extension_signin_header {
  text-align: center;
}
.exten-sign-header-logo {
  margin-bottom: 32px;
}
.exten-sign-header {
  color: var(--Shade-100, #000);
  /* Heading 06/H6/Semibold */
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px; /* 132% */
  margin-bottom: 12px;
  text-align: left;
}
.exten-sign-sub-header {
  color: #000;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
.LoginContainer input {
  height: 50px;
}
.LoginContainer button,
.LoginContainer button:hover {
  background-color: #ff5733;
  background-color: #ff5733;
  border: none;
  height: 50px;
}
.LoginContainer {
  width: 100%;
  margin: auto !important;
}
.errorMessage {
  color: red;
}
.signup-md {
  width: 47%;
  float: left;
}
.signup-md-left {
  width: 47%;
  float: left;
  margin-left: 6%;
}
.SignupContainer {
  margin-top: 50px;
}
.loginsignupContainer {
  margin-top: 50px;
}
.tabHeaderDesktop {
  width: 500px;
  margin: auto !important;
}
.data-loginsignupContainer {
  width: 575px;
  margin: auto;
}
.freshbag_signin_header {
  font-size: 28px;
  font-weight: 600;
  color: #000;
  /* margin-top: 5px!important; */
  text-align: center;
  margin-bottom: 20px;
}
.tabHeaderDesktop > div {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  /* padding-bottom: 13px; */
  cursor: pointer;
}
.tabHeaderDesktop .tabActive {
  border-bottom: 10px solid #000;
}
.tabInActive {
  border-bottom: 1px solid #dadada;
}
.data-loginsignupContainer > .row {
  margin: 0px;
}
.tabHeaderDesktop span {
  padding-bottom: 15px;
}
.LoginContainer .form-group .error.form-control {
  border-color: red;
}
.SignupContainer .form-group .invalid-feedback {
  display: inline;
}
.SignupContainer .form-group label {
  color: var(--Neutral-700, #7f8b93);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  margin-bottom: 2px;
}
.SignupContainer .form-group input {
  border-radius: 10px;
  border: 1px solid #cfd3d7;

  background: #fff;

  color: var(--Neutral-400, #000);
  /* font-family: Poppins; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  height: 50px;
}
.exten-login-container .form-group label {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 2px;
  color: #7f8b93;
}
.exten-login-container .form-group input {
  height: 50px;
  border-radius: 15px;
  /* font-family: Poppins; */
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.forgot-pass-sec {
  display: flex;
  justify-content: end;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
}
.dont-have-acc-sec {
  margin-top: 20px;
}
.dont-have-acc-sec p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}
.dont-have-acc-sec span,
.dont-have-acc-sec a,
.dont-have-acc-sec a:hover {
  color: #ff5733;
  cursor: pointer;
}
.account-set-up-sec-exten input {
  height: 17px;
  width: 17px;
  border: 1px solid #000;
  position: relative;
  top: 3px;
  margin-right: 10px;
}
.account-set-up-sec-exten {
  color: var(--Neutral-900, #374047);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
}

.account-set-up-sec-exten span {
  color: #ff5733;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.sign-up-btn-sec-exten {
  margin-top: 25px;
}
.sign-up-btn-sec-exten {
  background-color: #ff5733;
  border-radius: 18px;
  height: 64px;
  width: 100%;
  border: none;
  color: var(--Neutral-400, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.032px;
  text-transform: uppercase;
}
.errorMessage-signup {
  margin-top: 10px;
  margin-bottom: 10px;
}
.sign-up-btn-sec-exten:disabled {
  background: var(--Neutral-100, #eceeef);
  color: var(--Neutral-400, #bfc5c9);
}
